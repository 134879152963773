import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Footer from './Footer';
import Header from './Header';
import HomeButton from './HomeButton';
import StartLeaveRequestLink from './StartLeaveRequestLink'


const ActiveLinkStyle = {
  fontWeight: 'bold',
  fontSize: '1.2em',
  color: '#000',
  position: 'relative',
  padding: '10px',
  zIndex: '30',
  float: 'left',
  margin: '0 2px 0',
  display: 'block',
  background: '#fff',
  borderTopLeftRadius: '11px',
  borderTopRightRadius: '11px',
  boxShadow: '0px -2px 3px rgba(50, 50, 50, 0.75)',
}

export default function Layout({ noNav, children, noHeader }) {
  return (
    <>
      {noHeader
        ? children
        : noNav
          ? (
            <>
              <Header NoLogout />
              <HomeButton />
              <div id="content-bg" className="contentbg-gradient">
                <main>{children}</main>
              </div>
            </>
          )
          : (
            <>
              <Header />
              <HomeButton to="/Overview/" />
              <div id="content-bg" className="contentbg-gradient">
                <div className="clear">
                  <br />
                </div>
                <div id="tab-snav">
                  <Link to="/Overview/" activeStyle={ActiveLinkStyle}>
                    Leave Overview
                  </Link>
                  <Link to="/LawsByState/" activeStyle={ActiveLinkStyle}>
                    View Laws By State
                  </Link>
                  <StartLeaveRequestLink>
                    Submit A Leave Request
                  </StartLeaveRequestLink>
                </div>
                <main>{children}</main>
              </div>
            </>
          )}
      <Footer />
    </>
  )
}

Layout.defaultProps = {
  noNav: false,
  noHeader: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  noNav: PropTypes.bool,
  noHeader: PropTypes.bool,
}

export { ActiveLinkStyle }
