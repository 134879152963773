import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios';
import qs from 'qs';
import { useStaticQuery, graphql } from 'gatsby'
import navigate from '../utils/navigate'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Context as UserContext } from '../components/UserContext'
import random from '../utils/random';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexPage {
      site {
        siteMetadata {
          CBC_ENV
          home
          pathPrefix
        }
      }
    }
  `)
  const { CBC_ENV, home, pathPrefix } = data.site.siteMetadata

  const [state, setstate] = useState({
    Loading: false,
    Username: process.env.NODE_ENV === 'development' ? 'DT01011980' : '',
    Password: process.env.NODE_ENV === 'development' ? '3edc$RFV' : '',
    Error: '',
  })

  const { login } = useContext(UserContext)

  useEffect(() => {
    if (CBC_ENV === 'remote') {
      if (process.env.NODE_ENV !== 'development') {
        axios
          .get(`https://online.cbcins.com/DecisionOne/Token.ashx?v=${random()}`)
          .then((res) => {
            login(res.data.access_token, res.data.expires_in, false, false);
            navigate('/Overview')
          })
          .catch(() => {
            navigate(home)
          });
      }
    }
  }, [CBC_ENV, home, login])


  const handleUsernameChange = (e) => {
    e.persist()
    const Error = '';
    setstate(prev => ({ ...prev, Username: e.target.value, Error }));
  }
  const handlePasswordChange = (e) => {
    e.persist()
    const Error = '';
    setstate(prev => ({ ...prev, Password: e.target.value, Error }));
  }
  const handleLogin = (event) => {
    // process.env.REACT_APP_ENV
    event.preventDefault();

    let Error = '';
    // Validate Data
    const re = /^[a-zA-Z]{2}[0-9]{8}$/;
    if (!re.test(String(state.Username).toLowerCase())) Error = 'Invalid Username';

    if (state.Password.length !== 6 && state.Password.length < 8) Error = 'Invalid Password';

    // check for any errors and set state
    if (Error.length > 0) {
      setstate(prev => ({ ...prev, Error }));
      return false;
    }

    setstate(prev => ({ ...prev, Loading: true }));

    // Perform API call to data source

    const data = qs.stringify({
      userName: state.Username,
      password: state.Password,
      confirmPassword: state.Password,
      grant_type: 'password',
    });

    // Post to datasource with credentials
    const LoginURL = 'https://apps.cbcins.com/Token?AccountID=4701'
    // Example of using a variable inside of the HTML file to use inside of a react application
    // if(window.LoginURL)
    //   LoginURL = window.LoginURL
    axios
      .post(`${LoginURL}&V=${random()}`, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      })
      .then((res) => {
        // Log the user in
        login(res.data.access_token, res.data.expires_in, state.Username, state.Password);
        navigate('/Overview')
        setstate(prev => ({ ...prev, Error: '', Loading: false }));
      })
      .catch(() => {
        setstate(prev => ({ ...prev, Error: 'Invalid Login Credentials', Loading: false }));
      });
  }

  if (CBC_ENV === 'remote') {
    return (
      <Layout noNav>
        <SEO title="Home" />
        <div id="content-bg" className="contentbg-gradient">
          <div className="content-outline-998 viewRequest" style={{ textAlign: 'center' }}>
            <strong style={{ fontSize: '1.5em' }}>Checking your credentials...</strong>
            <br />
            <img
              src={`${pathPrefix}/styles/images/LoadingAnim.gif`}
              width="30%"
              alt=""
            />
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout noNav noHeader>
      <SEO title="Home" />
      <div className="loginwrapper">
        <table
          id="loginHeader"
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
          border="0"
          cellSpacing="0"
          cellPadding="0"
          align="center"
        >
          <tbody>
            <tr>
              <td id="loginLogo">
                <img
                  src={`${pathPrefix}/styles/images/loginlogo.png`}
                  height="100%"
                  alt=""
                />
              </td>
              <td
                valign="top"
                style={{ paddingLeft: '3em', paddingTop: '1.35em', textAlign: 'left' }}
              >
                <h2>Welcome to the DECISION<strong style={{ fontSize: '1em' }}>ONE</strong></h2>
                <h1 style={{ paddingTop: '.05em' }}>Leave Center!</h1>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          border="0"
          cellSpacing="0"
          cellPadding="0"
          style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '4.5em', width: '40em' }}
        >
          <tbody>
            <tr>
              <td>
                <div className="loginpic">&nbsp;</div>
                <div className="loginpanel">
                  <div className="log_feil_main">
                    <br />
                    <p
                      className="EmpLoginTitle"
                    >
                        Employee Login:
                    </p>
                    <div className="in_log_feild">
                      <input
                        type="text"
                        name="txtnme"
                        className="text_in"
                        placeholder="Username"
                        value={state.Username}
                        onChange={handleUsernameChange}
                        disabled={state.Loading}
                      />
                      <div className="q1" style={{ color: '#3AC6C7' }}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          style={{ display: 'block', cursor: 'default' }}
                          className="normalTip"
                          title="Your User Name is the first initials of your first name and last name followed by your date of birth (mmddyyyy) with no spaces in between. (For example: If John Smith was born on September 17, 1971, his ID would be JS09171971)"
                        >
                          <i
                            style={{
                              pointerEvents: 'none',
                            }}
                            className="fa fa-question-circle fa-lg white"
                            aria-hidden="true"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="in_log_feild" style={{ paddingBottom: 16 }}>
                      <input
                        type="password"
                        name="txtnme"
                        className="text_in_pass"
                        placeholder="Password"
                        value={state.Password}
                        onChange={handlePasswordChange}
                        disabled={state.Loading}
                      />
                      <div className="q2" style={{ color: '#3AC6C7' }}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          style={{ display: 'block', cursor: 'default' }}
                          className="normalTip"
                          title="Your Password: If this is your first time logging into the Leave of Absence Administration website, you will need to enter your temporary password. Your temporary password is the last six digits of your social security number. (For example: If John Smith’s full SS number is 123-45-6789, the password is 456789). After logging in, you will be prompted to change your password"
                        >
                          <i
                            style={{
                              pointerEvents: 'none',
                            }}
                            className="fa fa-question-circle fa-lg white"
                            aria-hidden="true"
                          />
                        </a>
                      </div>
                    </div>
                    <div id="loginbutton">
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */}
                      <a onClick={handleLogin}>
                        {state.Loading ? 'Loading...' : 'Login  '}
                        {!state.Loading && (
                          <i
                            className="fa fa-angle-double-right"
                            aria-hidden="true"
                          />
                        )}
                      </a>
                    </div>
                    <div className="loginError"><span>{state.Error ? 'Error: ' : ''}</span>{state.Error}</div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="loginContent">
        <div className="loginContentLeft">
          <h3>Providing Easy Access to:</h3>
          <ul className="fa-ul RevealRight">
            <li>
              <i className="fa-li fa color1 fa-check-circle color1" />FMLA
                Forms &amp; Policies
            </li>
            <li>
              <i className="fa-li fa color1 fa-check-circle color1" />Leave
                Laws by State
            </li>
            <li>
              <i className="fa-li fa color1 fa-check-circle color1" />Online
                Leave Submission
            </li>
            <li>
              <i className="fa-li fa color1 fa-check-circle color1" />Leave
                Tracking &amp; Approval
            </li>
          </ul>
        </div>
        <div className="loginContentRight" style={{ paddingTop: 2 }}>
            The Leave Center is designed to give information and documents
            necessary for you to successfully manage your Leave of Absence. If
            you require time off for three or more consecutive days due to any
            illness or pregnancy for you or your family members, you can use our
            online leave form to submit a request for leave.
        </div>
      </div>

    </Layout>
  )
}

export default IndexPage
