import React from 'react';


export default function Footer() {
  return (
    <div>
      <p className="clear">&nbsp;</p>
      <div className="footerWrapper">
        <div className="footerHomeContent">
          <div className="footerLeftDiv">
            Copyright © 2018 CBC. All Rights Reserved.{' '}
            <span className="lightRed">|</span>{' '}
            <a href="https://www.cbcins.com/Privacypolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            <br />
          </div>

          <div className="footerRightDiv">
            <a target="_blank" rel="noopener noreferrer" href="mailto:DecisionOneService@cbcins.com">
              <div className="EmailFooterBTN">24-Hour Response M-F</div>
            </a>
            <a href="tel:8888738326">
              <div className="PhoneFooterBTN">
                (888) 873-8326 M / F 6 - 5 (PST){' '}
              </div>
            </a>
          </div>
          <div className="footerLogo" />
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
        </div>
      </div>
    </div>
  );
}
